<template>
  <div class="surface-section px-4 py-8 md:px-6 lg:px-8">
    <div class="grid">
      <div class="col-12 md:col-6 flex align-items-center">
        <div>
          <div class="text-blue-600 text-xl mb-3">Parabéns!</div>
          <div class="text-900 text-4xl font-bold mb-6">Você possuí 7 dias grátis no Clinical Mind.</div>

          <div class="grid">
            <div class="col-12 md:col-6">
              <div class="text-900 font-medium mb-2">Próximo Passo</div>
              <div class="text-700 text-sm mb-3 line-height-3">Um email foi enviado para que você possa confimar seu acesso.</div>
            </div>
            <div class="col-12 md:col-6">
              <div class="text-900 font-medium mb-2">Periodo de Teste</div>
              <div class="text-700 text-sm mb-3 line-height-3">Você possuí 7 dias para testar o Clinical Mind, após este periodo você poderá ativar um dos nossos planos.</div>
            </div>
            <div class="col-12 md:col-6">
              <div class="text-900 font-medium mb-2">Pagamento</div>
              <div class="text-700 text-sm mb-3 line-height-3">Aceitamos todos os cartões de crédito e ganhe 10% de desconto no plano anual.</div>
            </div>
            <div class="col-12 md:col-6">
              <div class="text-900 font-medium mb-2">Muito Obrigado!</div>
              <div class="text-700 text-sm mb-3 line-height-3">Tenha uma experiencia unica no gerenciamento da sua clinica. A Clinical Mind agradece!</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 md:col-6">
        <img src="images/blocks/feature/feature-1.png" alt="Image" class="w-full">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Step3"
}
</script>

<style scoped>

</style>
